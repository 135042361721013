<template>
  <div>
    <div v-if="!stockOrderInfoApiDataIsLoading">
      <div class="row">
        <div class="col-12" v-if="stockOrderInfoApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{ stockOrderInfoApiDataErrorMessage }}
          </div>
        </div>
        <div class="col-12" v-if="stockOrderDocumentDownloadUrlApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{ stockOrderDocumentDownloadUrlApiDataErrorMessage }}
          </div>
        </div>
      </div>
      <div v-if="stockOrderInfoApiData != null">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="profile">
              <div class="profile-header">

                <div class="profile-header-cover"></div>

                <div class="profile-header-content">

                  <div class="profile-header-img">
                    <img src="/assets/img/document.png" alt="">
                  </div>

                  <div class="profile-header-info">
                    <h1 class="m-t-10 m-b-5">{{ stockOrderInfoApiData.type_name }}</h1>
                    <h3 class="m-b-5">{{ stockOrderInfoApiData.server_side_code }} du
                      {{ stockOrderInfoApiData.formatted_creation_date }}</h3>
                    <h3 class="m-b-5">{{ stockOrderInfoApiData.warehouse_name }} <span
                        v-if="stockOrderInfoApiData.warehouse_name_destination != null && stockOrderInfoApiData.warehouse_name_destination != ''"> vers {{ stockOrderInfoApiData.warehouse_name_destination }}</span>
                    </h3>
                    <!--<a href="#" class="btn btn-sm btn-yellow"><i class="fa fa-print text-black fa-fw fa-lg"></i> Imprimer</a>-->
                  </div>

                </div>


                <ul class="profile-header-tab nav nav-tabs">
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('home')"
                                          v-bind:class="{ 'active': tab.home }" class="nav-link"
                                          data-toggle="tab">DETAILS MOUVEMENT
                    ({{ stockOrderInfoApiData.stock_items_count }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('notes')"
                                          v-bind:class="{ 'active': tab.notes }" class="nav-link"
                                          data-toggle="tab">OBSERVATIONS
                    ({{ stockOrderInfoApiData.stock_notes_count }})</a></li>
                </ul>
              </div>
            </div>
            <div class="profile-content">
              <div class="tab-content p-0">
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.home }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a v-if="stockOrderDocumentDownloadUrlApiData == null"
                                                   class="btn btn-white m-b-10 mr-2" @click="onDocumentDownloadClick"><i
                                                    v-if="!stockOrderDocumentDownloadUrlApiDataIsLoading"
                                                    class="fa fa-download text-warning fa-fw fa-lg"></i><i
                                                    class="fa fa-spinner text-warning fa-fw fa-lg fa-spin"
                                                    v-if="stockOrderDocumentDownloadUrlApiDataIsLoading"></i> Télécharger</a>
                                                <a v-if="stockOrderDocumentDownloadUrlApiData != null &&  !stockOrderDocumentDownloadUrlApiDataIsLoading &&  stockOrderDocumentDownloadUrlApiData.pdf_url != null"
                                                   :href="stockOrderDocumentDownloadUrlApiData.pdf_url" target="_blank"
                                                   class="btn btn-white m-b-10 mr-2"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                                <a v-if="stockOrderDocumentDownloadUrlApiData != null &&  !stockOrderDocumentDownloadUrlApiDataIsLoading &&  stockOrderDocumentDownloadUrlApiData.excel_url != null"
                                                   :href="stockOrderDocumentDownloadUrlApiData.excel_url"
                                                   target="_blank" class="btn btn-white m-b-10 mr-2"><i
                                                    class="fa fa-file-excel text-success fa-fw fa-lg"></i> Excel</a>
                                            </span>
                      <!--
                      <b-dropdown split text="Actions" class="btn-group pull-right hidden-print m-r-10" variant="default">
                          <b-dropdown-item href="#">Action 1</b-dropdown-item>
                          <b-dropdown-item href="#">Action 2</b-dropdown-item>
                          <b-dropdown-item href="#">Action 3</b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#">Action 4</b-dropdown-item>
                      </b-dropdown>
                      -->
                      <h1>{{ stockOrderInfoApiData.group_name }}<br/>
                        <small>{{ stockOrderInfoApiData.server_side_code }} du
                          {{ stockOrderInfoApiData.formatted_creation_date }}
                        </small><br/>
                        <small
                            v-if="stockOrderInfoApiData.sales_order_id != 0 && stockOrderInfoApiData.sales_order_code != ''">
                          Référence Facture : <b><a href="javascript:;"
                                                    @click="onSalesOrderCodeClick(stockOrderInfoApiData.sales_order_id)">{{ stockOrderInfoApiData.sales_order_code }}</a></b></small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: red; padding: 5px;"
                         v-if="stockOrderInfoApiData.type_code == 'DBT'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{stockOrderInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: green; padding: 5px;"
                         v-if="stockOrderInfoApiData.type_code == 'CDT'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{stockOrderInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header" style="background: orange; padding: 5px;"
                         v-if="stockOrderInfoApiData.type_code == 'TRF' || stockOrderInfoApiData.type_code == 'VRT'">
                      <h1>
                        <!--<small class="text-white f-fw-300">{{stockOrderInfoApiData.type_name}}</small>-->
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ stockOrderInfoApiData.company_name }}</strong><br/>
                          {{ stockOrderInfoApiData.company_address_line }}<br/>
                          {{ stockOrderInfoApiData.company_cluster_name }},
                          {{ stockOrderInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ stockOrderInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Emplacement</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ stockOrderInfoApiData.warehouse_name }}</strong><br/>
                          {{ stockOrderInfoApiData.location_name }}<br/>
                          <span v-if="stockOrderInfoApiData.warehouse_name_destination != ''"><br/>Destionation<br/>
                                                    {{ stockOrderInfoApiData.warehouse_name_destination }}<br/>
                                                    {{ stockOrderInfoApiData.location_name_destination }}<br/>
                                                    </span>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Validation</small>
                        <div class="date text-inverse m-t-5">

                        </div>
                        <div class="invoice-detail">
                          Initié Par <span
                            class="date text-inverse m-t-5">{{ stockOrderInfoApiData.seller_name }}</span><br/>
                          Validé Par <span
                            class="date text-inverse m-t-5">{{ stockOrderInfoApiData.approved_by_name }}</span><br/>
                          Date de Validation <span
                            class="date text-inverse m-t-5">{{ stockOrderInfoApiData.approval_date }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-header -->
                    <h3>{{ stockOrderInfoApiData.items_count }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-right" width="5%">QTE</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in stockOrderInfoApiData.stock_order_items"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left"><b>{{ order_item.product_name }}</b></td>
                            <td class="text-right"><b>{{ order_item.quantity|formatDecimalNumber }}</b></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <!--
                          <div class="invoice-price-row">
                              <div class="sub-price">
                                  <small>TOTAL TTC</small>
                                  <span class="text-inverse">{{stockOrderInfoApiData.order_amount_ttc|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>REGLEMENTS</small>
                                  <span class="text-inverse">{{stockOrderInfoApiData.order_total_payments|formatDecimalNumber}}</span>
                              </div>
                              <div class="sub-price">
                                  <i class="fa fa-equals text-muted"></i>
                              </div>
                              <div class="sub-price">
                                  <small>RESTE A PAYER</small>
                                  <span class="text-inverse">{{stockOrderInfoApiData.order_remainder_payments|formatDecimalNumber}}</span>
                              </div>
                          </div>
                          -->
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>TOTAL REFERENCES</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{ stockOrderInfoApiData.stock_items_count|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ stockOrderInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ stockOrderInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ stockOrderInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.notes }">
                  <div class="invoice">
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>Observations<br/>
                        <small>{{ stockOrderInfoApiData.server_side_code }} du
                          {{ stockOrderInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <h3>{{ stockOrderInfoApiData.stock_notes_count }} Observation(s)</h3>
                    <div class="invoice-content">
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th class="text-left">DATE</th>
                            <th class="text-center">AGENT</th>
                            <th class="text-right">OBSERVATION</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_note,index) in stockOrderInfoApiData.stock_order_notes"
                              :key="index">
                            <td class="text-left">{{ order_note.date }}</td>
                            <td class="text-center">{{ order_note.writer }}</td>
                            <td class="text-right">{{ order_note.note }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ stockOrderInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ stockOrderInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ stockOrderInfoApiData.company_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/stock/stock-dashboard-page-api'

export default {
  name: 'stock-order-component',
  components: {},
  props: ['itemId'],
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    //
    show: function (x) {
      this.tab.home = false;
      this.tab.notes = false;

      switch (x) {
        case 'home':
          this.tab.home = true;
          break;
        case 'notes':
          this.tab.notes = true;
          break;
        default:
          this.tab.home = true;
          break;
      }
    },

    onSalesOrderCodeClick(id) {
      console.log(id);
    },

    onDocumentDownloadClick() {
      if (this.itemId) {
        this.getStockOrderDocumentDownloadUrlData(this.itemId);
      }
    },

    //Other Methods
    async getStockOrderInfoData(stockOrderId) {
      this.stockOrderInfoApiDataIsLoading = true;
      this.stockOrderInfoApiDataSuccessMessage = null;
      this.stockOrderInfoApiDataWarningMessage = null;
      this.stockOrderInfoApiDataErrorMessage = null;
      this.stockOrderInfoApiDataValidationErrors = [];
      try {
        const response = await pageApi.getStockOrderInfoData(stockOrderId);
        //console.log(response);
        this.stockOrderInfoApiData = response.data;
        this.stockOrderInfoApiDataIsLoading = false;
        this.stockOrderInfoApiDataSuccessMessage = null;
        this.stockOrderInfoApiDataWarningMessage = null;
        this.stockOrderInfoApiDataErrorMessage = null;
        this.stockOrderInfoApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);

          this.stockOrderInfoApiDataIsLoading = false;
          this.stockOrderInfoApiDataSuccessMessage = null;
          this.stockOrderInfoApiDataWarningMessage = null;
          this.stockOrderInfoApiDataErrorMessage = error.response.data.message;
          this.stockOrderInfoApiDataValidationErrors = [];


        } else {
          this.stockOrderInfoApiDataIsLoading = false;
          this.stockOrderInfoApiDataSuccessMessage = null;
          this.stockOrderInfoApiDataWarningMessage = null;
          this.stockOrderInfoApiDataErrorMessage = error.message;
          this.stockOrderInfoApiDataValidationErrors = [];
        }
      }
    },

    async getStockOrderDocumentDownloadUrlData(stockOrderId) {
      this.stockOrderDocumentDownloadUrlApiDataIsLoading = true;
      this.stockOrderDocumentDownloadUrlApiDataErrorMessage = null;
      try {
        const response = await pageApi.getStockOrderDocumentDownloadUrlData(stockOrderId);
        //console.log(response);
        this.stockOrderDocumentDownloadUrlApiData = response.data;
        this.stockOrderDocumentDownloadUrlApiDataIsLoading = false;
        this.stockOrderDocumentDownloadUrlApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.stockOrderDocumentDownloadUrlApiDataIsLoading = false;
          this.stockOrderDocumentDownloadUrlApiDataErrorMessage = error.response.data.message;
        } else {
          this.stockOrderDocumentDownloadUrlApiDataIsLoading = false;
          this.stockOrderDocumentDownloadUrlApiDataErrorMessage = error.message;
        }
      }
    },

  },
  data: function () {

    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      //
      tab: {
        home: true,
        notes: false,
      },

      //API Data
      //
      stockOrderInfoApiData: null,
      stockOrderInfoApiDataIsLoading: false,
      stockOrderInfoApiDataSuccessMessage: null,
      stockOrderInfoApiDataWarningMessage: null,
      stockOrderInfoApiDataErrorMessage: null,
      stockOrderInfoApiDataValidationErrors: [],
      //
      stockOrderDocumentDownloadUrlApiData: null,
      stockOrderDocumentDownloadUrlApiDataIsLoading: false,
      stockOrderDocumentDownloadUrlApiDataErrorMessage: null,
    };
  },
  created: function () {
    if (this.itemId) {
      this.getStockOrderInfoData(this.itemId);
    }
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
